import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production' && process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['filters/setSelectedRange'],
      // ignoredActionPaths: ['filter.selectedRange.startDate', 'filter.selectedRange'],
      ignoredPaths: ['attendanceReport', 'filter.selectedRange.startDate', 'filter.selectedRange']
    },
    immutableCheck: {
      ignoredPaths: ['attendanceReport', 'filter', 'filter.selectedRange.startDate', 'filter.selectedRange', 'filter.selectedTerminus', 'filter.selectedEmployees'],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
