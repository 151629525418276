/* eslint-disable react/no-danger */
import type { FC } from 'react';

const rawHTML = `
  <style>
    @keyframes slide {
      0% {
        transform: translateX(-25%);
      }

      100% {
        transform: translateX(25%);
      }
    }

    .mud-wasm {
      animation: slide 4s ease-in-out infinite alternate;
      background-image: linear-gradient(-60deg, #0093D3 50%, #0093A3 50%);
      bottom: 0;
      left: -50%;
      opacity: .5;
      position: fixed;
      right: -50%;
      top: 0;
      z-index: -1
    }

    .mud-wasm-cont {
      left: 50%;
      padding: 10vmin;
      position: fixed;
      text-align: center;
      top: 50%;
      transform: translate(-80%, -60%)
    }
  </style>
  <div style="height:100%;width:100%;margin:0;position:fixed;">
    <div class="mud-wasm"></div>
    <div class="mud-wasm" style="animation-direction:alternate-reverse;animation-duration:2s;"></div>
    <div class="mud-wasm" style="animation-duration:3s;"></div>
    <div class="mud-wasm-cont ">
      <svg width="200%" height="200%" viewBox="30 70 780 140" xmlns="http://www.w3.org/2000/svg">
        <path fill="#F29100"
          d="M389.99 208.7L389.99 150.41L389.97 150.41L358.63 119.07L358.6 119.05L331.7 92.15L389.99 92.15L448.27 150.42L389.99 208.7ZM331.7 208.7L358.63 181.78L358.63 208.7L331.7 208.7Z" />
        <path fill="#FFFFFF"
          d="M676.87 158.67L676.87 92.15L645.51 92.15L645.51 159.27C645.51 159.31 645.53 159.35 645.53 159.39C645.53 171.77 635.48 178.71 623.11 178.71C610.72 178.71 600.69 171.77 600.69 159.39L600.69 92.15L569.33 92.15L569.33 158.92C569.33 159.08 569.3 159.23 569.3 159.39C569.3 189.1 593.39 210.31 623.11 210.31C652.81 210.31 676.91 189.1 676.91 159.39C676.91 159.15 676.87 158.92 676.87 158.67Z" />
        <path fill="#FFFFFF"
          d="M560.38 177.33C560.38 194.65 546.34 208.7 529 208.7L457.26 208.7L457.26 92.15L529 92.15L529 92.24C544.28 93.16 555.85 105.81 555.85 121.31C555.85 133.05 549.45 143.13 539.45 147.77C551.64 152.07 560.38 163.67 560.38 177.33ZM488.69 139.23L514.21 139.23C519.79 139.23 524.3 134.72 524.3 129.14C524.3 123.57 519.79 119.05 514.21 119.05L488.69 119.05L488.69 139.23ZM519.09 181.8C524.66 181.8 529.19 177.2 529.19 171.62C529.19 166.04 524.66 161.53 519.09 161.53L488.69 161.53L488.69 181.8L519.09 181.8Z" />
        <path fill="#FFFFFF"
          d="M389.99 92.15L416.88 119.05L434.9 119.05L434.9 92.15L389.99 92.15ZM313.81 92.15L313.81 119.05L358.6 119.05L331.7 92.15L313.81 92.15ZM358.63 119.07L358.63 208.7L389.99 208.7L389.99 150.41L389.97 150.41L358.63 119.07Z" />
        <path fill="#FFFFFF"
          d="M152.49 92.15L125.49 136.47L98.6 92.15L62.72 92.15L62.72 208.71L94.11 208.71L94.11 141.35L125.49 186.3L156.91 141.5L156.91 208.71L188.29 208.71L188.29 92.15L152.49 92.15Z" />
        <path fill="#FFFFFF"
          d="M206.21 92.15L300.3 92.15L300.3 118.98L237.54 118.98L237.54 136.92L295.87 136.92L295.87 163.87L237.54 163.87L237.54 181.8L300.3 181.8L300.3 208.64L206.21 208.64L206.21 92.15Z" />
        <path fill="#FFFFFF"
          d="M740.04 210.31C729.65 210.31 719.77 208.69 710.42 205.47C701.07 202.23 692.81 197.39 685.62 190.93L703.53 169.48C715.16 178.77 727.7 183.42 741.19 183.42C745.51 183.42 748.81 182.73 751.09 181.36C753.34 179.96 754.49 178.01 754.49 175.46L754.49 175.14C754.49 173.92 754.2 172.85 753.65 171.91C753.1 170.97 752.07 170.06 750.58 169.17C749.08 168.29 747.1 167.44 744.61 166.61C742.11 165.78 738.88 164.93 734.9 164.04C728.7 162.6 722.96 161.03 717.63 159.33C712.33 157.61 707.71 155.4 703.78 152.69C699.86 149.98 696.76 146.62 694.49 142.59C692.23 138.56 691.09 133.56 691.09 127.59L691.09 127.27C691.09 121.86 692.11 116.88 694.16 112.36C696.21 107.83 699.18 103.91 703.12 100.6C707.05 97.28 711.78 94.72 717.31 92.89C722.85 91.07 729.08 90.17 736.05 90.17C746.02 90.17 754.89 91.47 762.7 94.08C770.49 96.71 777.54 100.58 783.84 105.69L767.76 128.49C762.44 124.73 756.99 121.88 751.41 119.94C745.82 118.01 740.43 117.05 735.23 117.05C731.37 117.05 728.45 117.76 726.52 119.2C724.58 120.63 723.61 122.4 723.61 124.5L723.61 124.83C723.61 126.15 723.93 127.31 724.53 128.31C725.14 129.3 726.19 130.21 727.68 131.04C729.17 131.88 731.22 132.7 733.82 133.53C736.41 134.35 739.72 135.21 743.7 136.09C750.33 137.53 756.34 139.21 761.7 141.14C767.05 143.07 771.63 145.43 775.38 148.19C779.14 150.94 782.02 154.29 784.02 158.2C786.01 162.13 787.01 166.8 787.01 172.21L787.01 172.53C787.01 178.5 785.87 183.79 783.6 188.44C781.34 193.08 778.16 197.02 774.07 200.28C769.96 203.53 765.04 206.02 759.28 207.73C753.53 209.44 747.12 210.31 740.04 210.31Z" />
      </svg>
    </div>
  </div>
`;

const MetbusSplashScreen: FC = () => (
  <div>
    <div dangerouslySetInnerHTML={{ __html: rawHTML }} />
  </div>
);

export default MetbusSplashScreen;
