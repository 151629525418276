import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IEmployee } from 'types/employee';
import { ITerminus } from 'types/terminus';
import { endOfDay, startOfDay } from 'date-fns';
import { DateRange, DefinedRange } from 'components/custom/datePicker/useDefaultRanges';
import type { AppThunk } from '../store';

export interface FilterState {
  isOpen: boolean;
  selectedRange: DateRange;
  selectedTerminus: ITerminus[];
  selectedEmployees: IEmployee[];
}

const initialState: FilterState = {
  isOpen: true,
  selectedRange: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  },
  selectedEmployees: [],
  selectedTerminus: []
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSelectedEmployees(state: FilterState, action: PayloadAction<{ selected: IEmployee[] }>): void {
      const { selected } = action.payload;
      if (!selected || selected.length === 0) state.selectedEmployees = undefined;
      else state.selectedEmployees = selected;
    },
    setSelectedTerminus(state: FilterState, action: PayloadAction<{ selected: ITerminus[] }>): void {
      const { selected } = action.payload;
      if (!selected || selected.length === 0) state.selectedTerminus = undefined;
      else state.selectedTerminus = selected;
    },
    setSelectedRange(state: FilterState, action: PayloadAction<{ range }>): void {
      const { range } = action.payload;
      state.selectedRange = range;
    },
    switchOpen(state: FilterState): void {
      state.isOpen = !state.isOpen;
    },
  }
});

export const { reducer } = slice;

export const setSelectedEmployees = (data: IEmployee[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSelectedEmployees({ selected: data }));
};

export const setSelectedTerminus = (data: ITerminus[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSelectedTerminus({ selected: data }));
};

export const setSelectedRange = (data: DefinedRange): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSelectedRange({ range: { startDate: data.startDate, endDate: data.endDate } }));
};

export const switchOpen = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.switchOpen());
};
export default slice;
