export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const sumValues = (items: any[], prop: string) => items.reduce((a, b) => a + b[prop], 0);

export const maxValue = (items: any[], prop: string) => items.reduce((a, b) => (a < b[prop] ? b[prop] : a), 0);
export const minValue = (items: any[], prop: string) => items.reduce((a, b) => (a > b[prop] ? b[prop] : a), 0);
