import { combineReducers } from '@reduxjs/toolkit';
import { reducer as filterReducer } from '../slices/filter';
import { reducer as commonDataReducer } from '../slices/commonData';

const appReducer = combineReducers({
  filter: filterReducer,
  commonData: commonDataReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

// const rootReducer = combineReducers({
//   filter: filterReducer,
//   commonData: commonDataReducer,
//   attendanceReport: attendanceReportRequestReducer
// });

export default rootReducer;
