import { FC } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { AppBarProps } from '@mui/material';
import MetbusLogo from 'components/MetbusLogo';
import MenuIcon from 'icons/Menu';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import AccountPopover from 'components/shared/AccountPopover';
import useSettings from 'hooks/useSettings';
import { THEMES } from '../../constants';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      height: '64px',
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarOpen, ...other } = props;
  const { settings, saveSettings } = useSettings();
  const { theme } = settings;
  const setTheme = () => {
    if (theme === THEMES.METBUSLIGHT) {
      saveSettings({ ...settings, theme: THEMES.METBUSDARK });
    } else saveSettings({ ...settings, theme: THEMES.METBUSLIGHT });
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon
              fontSize="small"
              sx={{ mr: 1 }}
            />
            <MetbusLogo
              height="50"
              width="120"
            />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 1 }}>
          <IconButton
            color="inherit"
            onClick={setTheme}
          >
            {theme === THEMES.METBUSLIGHT ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton>
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};

export default DashboardNavbar;
