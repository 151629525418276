import { ThemeOptions } from '@mui/material/styles';

export const metbusLight: ThemeOptions = {
  palette: {
    mode: 'light',
    action: {
      active: '#6b778c'
    },
    background: {
      default: '#f4f5f7',
      paper: '#ffffff'
    },
    primary: {
      main: '#0093b3',
    },
    secondary: {
      main: '#f59e24',
    },
    text: {
      secondary: 'rgba(0,0,0,0.5)',
      primary: '#000',
      disabled: 'rgba(0,0,0,0.3)',
      //   hint: 'rgba(255,255,255,0.5)',
    },
  },
  typography: {
    fontFamily: 'Raleway',
    button: {
      fontWeight: 600
    },
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

export const metbusDark: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#0093b3',
      contrastText: '#ffffff',
    },
    background: {
      default: '#121212', // default: '#111111',
      paper: '#212121' // paper: '#212122',
    },
    secondary: {
      main: '#f59e24',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    text: {
      secondary: 'rgba(255,255,255,0.5)',
      primary: '#ffffff',
      disabled: 'rgba(255,255,255,0.3)',
    //   hint: 'rgba(255,255,255,0.5)',
    },
    error: {
      main: '#f44336',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ff9800',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    info: {
      main: '#2196f3',
      contrastText: '#ffffff',
    },
    success: {
      main: '#4caf50',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    divider: 'rgba(255,255,255,0.12)',
  },
  typography: {
    fontFamily: 'Raleway',
    button: {
      fontWeight: 600
    },
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};
