import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { LoginResponse, IUser } from 'types/loginResponse';
import { setAccessToken, add401Interceptor } from 'api/client';
import { Login, Me } from 'api/authenticate';
import { useDispatch } from 'store';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user?: IUser;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}
interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: IUser | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: IUser;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (loginResponse: LoginResponse | null): void => {
  if (loginResponse && loginResponse.succeeded) {
    const { accessToken } = loginResponse;
    localStorage.setItem('accessToken', accessToken);
    setAccessToken(accessToken);
  } else {
    localStorage.removeItem('accessToken');
    setAccessToken(null);
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchStore = useDispatch();
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) setAccessToken(accessToken);
        const user = await Me();
        if (accessToken && user) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string): Promise<void> => {
    const response = await Login({ username, password });
    if (typeof (response) === 'string') throw new Error(response);
    const loginResponse : LoginResponse = response;
    setSession(loginResponse);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: loginResponse.user
      }
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    dispatchStore({ type: 'USER_LOGOUT' });
  };

  add401Interceptor(logout);
  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
