import client from 'api/client';
import { ITerminus } from 'types/terminus';
import { capitalizeString } from 'utils/stringHelper';

export async function getTerminus() : Promise<ITerminus[] | null> {
  try {
    const terminus = await client.get<ITerminus[]>('/CostCentres');
    if (terminus && terminus.data) return terminus.data.map((t) => ({ ...t, name: capitalizeString(t.name) }));
    return undefined;
  } catch {
    return undefined;
  }
}
