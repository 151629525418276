import { useEffect, useRef } from 'react';
import { es } from 'date-fns/locale';
import {
  addDays, startOfWeek,
  endOfWeek,
  addWeeks,
  startOfMonth,
  endOfMonth,
  addMonths,
  endOfDay,
  startOfDay
} from 'date-fns';
// import { cl } from 'date-fns/locale';

export type DefinedRange = {
  startDate: Date;
  endDate: Date;
  label: string;
  type: TypeDateRanger;
};
export interface DateRange {
  startDate?: Date;
  endDate?: Date;
}
export type TypeDateRanger = 'Today' | 'Yesterday' | 'ThisWeek' | 'LastWeek' | 'Last7Days' | 'ThisMonth' | 'LastMonth' | 'Custom';

export const defaultLocale : Locale = {
  ...es,
  code: 'es_CL',
  options: { weekStartsOn: 1 }
};
// = { code: 'es_CL', options: { weekStartsOn: 1 } };

export const getDefaultRanges = (date: Date): DefinedRange[] => [
  {
    label: 'Hoy',
    startDate: startOfDay(date),
    endDate: endOfDay(date),
    type: 'Today'
  },
  {
    label: 'Ayer',
    startDate: startOfDay(addDays(date, -1)),
    endDate: endOfDay(addDays(date, -1)),
    type: 'Yesterday'
  },
  {
    label: 'Esta semana',
    startDate: startOfWeek(date, { locale: defaultLocale }),
    endDate: endOfWeek(date, { locale: defaultLocale }),
    type: 'ThisWeek'
  },
  {
    label: 'La semana pasada',
    startDate: startOfWeek(addWeeks(date, -1), { locale: defaultLocale }),
    endDate: endOfWeek(addWeeks(date, -1), { locale: defaultLocale }),
    type: 'LastWeek'
  },
  {
    label: 'Los últimos 7 días',
    startDate: startOfDay(addWeeks(date, -1)),
    endDate: endOfDay(date),
    type: 'Last7Days'
  },
  {
    label: 'Este mes',
    startDate: startOfMonth(date),
    endDate: endOfMonth(date),
    type: 'ThisMonth'
  },
  {
    label: 'El mes pasado',
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1)),
    type: 'LastMonth'
  }
];

const useDefaultRanges = (date: string) => {
  const now = new Date(date);
  const defaultRanges = useRef<DefinedRange[]>(getDefaultRanges(now));

  useEffect(() => {
    defaultRanges.current = getDefaultRanges(new Date());
  }, [date]);

  const getTypeDateRanger = (startDate: Date, endDate: Date): DefinedRange => {
    const definedRange = defaultRanges.current.find((x) => JSON.stringify(x.startDate) === JSON.stringify(startDate) && JSON.stringify(x.endDate) === JSON.stringify(endDate));
    if (typeof definedRange !== 'undefined') {
      return definedRange;
    }
    return {
      label: 'Personalizado',
      startDate,
      endDate,
      type: 'Custom'
    };
  };

  return {
    defaultRanges: defaultRanges.current,
    getTypeDateRanger,
  };
};

export default useDefaultRanges;
