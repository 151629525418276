import client from 'api/client';
import { LoginResponse, IUser, RequestTokenWithPermissions } from 'types/loginResponse';
import token from './token';

export async function Me(): Promise<IUser | undefined> {
  try {
    const user = await client.get<IUser>('/Login/Me');
    if (user && user.data) return user.data;
    return undefined;
  } catch {
    return undefined;
  }
}
export interface LoginProps {
  username: string,
  password: string
}

export async function Login(loginProps: LoginProps): Promise<LoginResponse | string> {
  try {
    const login = await token.post<RequestTokenWithPermissions>('/RequestToken', loginProps);
    if (login && login.data) {
      // eslint-disable-next-line no-debugger
      debugger;
      const user = {
        userName: 'mp',
        name: 'manuel',
        email: 'mp@cl',
        profile: '',
        rut: '15605017',
        rol: 796,
        lastLogin: new Date()
      };
      return {
        accessToken: login.data.result.token,
        user,
        succeeded: true,
        message: login.data.result.message
      };
    }
  } catch (err) {
    if (err.response.data && err.response.data.message) return err.response.data.message;
    return 'Ha ocurrido un error en la autentificación.';
  }
}
export interface ChangePasswordProps {
  userName: string,
  currentPassword: string,
  newPassword: string
}

export async function ChangePassword(changeProps: ChangePasswordProps): Promise<{ ok: boolean, message: string }> {
  try {
    const response = await client.post<LoginResponse>('/Login/ChangePassword', changeProps);
    if (response) return { ok: true, message: 'Se ha modificado correctamente la contraseña.' };
    return { ok: false, message: 'Ha ocurrido un error.' };
  } catch (err) {
    if (err.response.status === 400) return { ok: false, message: 'Contraseña incorrecta.' };
    return { ok: false, message: 'Ha ocurrido un error al cambiar la contraseña.' };
  }
}
