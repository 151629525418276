import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IEmployee } from 'types/employee';
import { ITerminus } from 'types/terminus';
import { getEmployees } from 'api/employees';
import { getTerminus } from 'api/terminus';
import { descendingComparator } from 'utils/comparerHelper';
import type { AppThunk } from '../store';

export interface CommonDataState {
  employees: IEmployee[];
  terminus: ITerminus[];
}

const initialState: CommonDataState = {
  employees: [],
  terminus: []
};

const slice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {
    setBaseData(state: CommonDataState, action: PayloadAction<{ employees: IEmployee[], terminus: ITerminus[] }>): void {
      const { employees, terminus } = action.payload;
      state.employees = employees;
      state.terminus = terminus;
    },
    updateBaseEmployees(state: CommonDataState, action: PayloadAction<{ employees: IEmployee[] }>): void {
      const { employees } = action.payload;
      if (employees && state.employees !== employees) state.employees = employees;
    },
    updateBaseTerminus(state: CommonDataState, action: PayloadAction<{ terminus: ITerminus[] }>): void {
      const { terminus } = action.payload;
      if (terminus && state.terminus !== terminus) state.terminus = terminus;
    }
  }
});

export const { reducer } = slice;

export const setBaseData = () : AppThunk => async (dispatch): Promise<void> => {
  Promise.all([getEmployees(), getTerminus()])
    .then((values) => {
      const [employees, terminus] = values;
      const terminusOrder = terminus.sort((a, b) => -descendingComparator(a, b, 'internalCode'));
      dispatch(slice.actions.setBaseData({ employees, terminus: terminusOrder }));
    });
};

export const updateBaseEmployees = (data: IEmployee[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateBaseEmployees({ employees: data }));
};

export const updateBaseTerminus = (data: ITerminus[]): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateBaseTerminus({ terminus: data }));
};

export default slice;
