import { Box, Typography, Divider, Button } from '@mui/material';
import NavSection from 'components/NavSection';
import Scrollbar from 'components/Scrollbar';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { capitalizeString } from 'utils/stringHelper';
import sections from './NavSectionsNames';
import useAuth from '../../hooks/useAuth';
import AvatarCustom from './AvatarCustom';

const Content = () => {
  const { user } = useAuth();
  const location = useLocation();

  const filter = user.profile !== 'Admin' ? sections.map((s) => (
    {
      title: s.title,
      items: s.items.filter((x) => x.path !== '/userAccounts')
    }
  )) : sections;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            {/* <RouterLink to="/dashboard/account"> */}
            <AvatarCustom
              name={capitalizeString(user.name)}
              others={{
                cursor: 'pointer',
                height: 48,
                width: 48
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {capitalizeString(user.name)}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {user.profile}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        {/* Aqui va la lista de items... */}
        <Box sx={{ p: 2 }}>
          {filter.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        { process.env.NODE_ENV !== 'production' && (
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box>
        )}
      </Scrollbar>
    </Box>
  );
};

export default Content;
