import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography
} from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import useSettings from 'hooks/useSettings';
import {
  LoginJWT
} from '../../components/authentication/login';
import MetbusLogo from '../../components/MetbusLogo';
// import useAuth from '../../hooks/useAuth';
import { THEMES } from '../../constants';

// const platformIcons = {
//   JWT: '/static/icons/jwt.svg'
// };

const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => (
    {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    }
  )
);

const Login: FC = () => {
  // const { platform } = useAuth() as any;
  const { settings } = useSettings();
  let contrastColor = false;
  if (settings && settings.theme && settings.theme === THEMES.METBUSLIGHT) {
    contrastColor = true;
  }

  return (
    <DashboardLayoutRoot>
      <Helmet>
        <title>Inicio de Sesión</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <RouterLink to="/">
              <MetbusLogo
                height="60"
                width="200"
                contrastColor={contrastColor}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Iniciar Sesión
                  </Typography>
                  {/* <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Log in on the internal platform
                  </Typography> */}
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                >
                  {/* <img
                    alt="Auth platform"
                    src={platformIcons[platform]}
                  /> */}
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <LoginJWT />
              </Box>
              <Divider sx={{ my: 3 }} />
              {/* <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/register"
                variant="body2"
              >
                Create new account
              </Link> */}

              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/"
                variant="body2"
              >
                Olvidaste tu contraseña?
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </DashboardLayoutRoot>
  );
};

export default Login;
