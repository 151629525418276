import axios, { AxiosInstance } from 'axios';
import { stringify } from 'qs';
import AxiosMockAdapter from 'axios-mock-adapter';

const client: AxiosInstance = (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_USE_MOCK === 'true') ? axios.create() : axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    accept: '*/*',
    'content-type': 'application/json',
  },
  paramsSerializer: (p) => stringify(p, { arrayFormat: 'repeat' }),
});

export const add401Interceptor = (logoutFunct: () => void) => {
  client.interceptors.response.use(
    undefined,
    (err) => {
      if (err.response.status === 401 || (err.response.data && err.response.data.message === '401 Unauthorized')) {
        logoutFunct();
      }
      return Promise.reject(err);
    }
  );
};

/**
   * Add default header to the ClientInstance! (Bearer)
   * @param addAccessToken
   */
export const setAccessToken = (accessToken: string | null) => {
  if (accessToken) client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  else delete client.defaults.headers.common.Authorization;
};

export const mock = (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_USE_MOCK === 'true') ? new AxiosMockAdapter(client, { delayResponse: 5 }) : new AxiosMockAdapter(axios.create());

export default client;
