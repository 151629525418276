import axios, { AxiosInstance } from 'axios';
import { stringify } from 'qs';
import AxiosMockAdapter from 'axios-mock-adapter';

/**
 * Leer esta documentacion sobre cors
 * https://create-react-app.dev/docs/proxying-api-requests-in-development/
 */
const token: AxiosInstance = (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_USE_MOCK === 'true') ? axios.create() : axios.create({
  baseURL: process.env.REACT_APP_API_TOKEN,
  headers: {
    accept: '*/*',
    'content-type': 'application/json',
  },
  paramsSerializer: (p) => stringify(p, { arrayFormat: 'repeat' }),
});

export const add401Interceptor = (logoutFunct: () => void) => {
  token.interceptors.response.use(
    undefined,
    (err) => {
      if (err.response.status === 401 || (err.response.data && err.response.data.message === '401 Unauthorized')) {
        logoutFunct();
      }
      return Promise.reject(err);
    }
  );
};

export const mockToken = (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_USE_MOCK === 'true') ? new AxiosMockAdapter(token, { delayResponse: 5 }) : new AxiosMockAdapter(axios.create());

export default token;
