export const capitalizeString = (s: string) => {
  const space = ' ';
  const parsedString = s.split(space);
  let result = '';
  parsedString.forEach((p, index) => {
    if (p.length > 1) {
      result += p.charAt(0).toUpperCase() + p.slice(1).toLowerCase();
      if (index !== parsedString.length - 1) result += space;
    }
  });
  return result;
};

export const formatRut = (s: string) => {
  const rut = s.split('-');
  let result = rut[0].split('').reverse().reduce((oldC, newC, index) => {
    if (index % 3 === 0 && index !== 0) oldC += `.${newC}`;
    else oldC += newC;
    return oldC;
  }, '');
  result = result.split('').reverse().join('');
  return `${result}-${rut[1]}`;
};

export const formatAccentMark = (s: string) => s.split('').reduce((oldValue, newValue) => {
  switch (newValue) {
    case 'á':
      return `${oldValue}a`;
    case 'é':
      return `${oldValue}e`;
    case 'í':
      return `${oldValue}i`;
    case 'ó':
      return `${oldValue}o`;
    case 'ú':
      return `${oldValue}u`;
    default:
      return `${oldValue}${newValue}`;
  }
}, '');

export const removeAccents = (str: string) => {
  const acentos = { á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u', Á: 'A', É: 'E', Í: 'I', Ó: 'O', Ú: 'U' };
  return str.split('').map((letra) => acentos[letra] || letra).join('').toString();
};
type PrimitiveType = string | Symbol | number | boolean;
export function isPrimitive(value: any): value is PrimitiveType {
  return (
    typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || typeof value === 'symbol'
  );
}
