import { Suspense, lazy } from 'react';
// import type { PartialRouteObject } from 'react-router';
import { Navigate, RouteObject } from 'react-router-dom';
import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'pages/shared/DashboardLayout';
import DocsLayout from 'components/docs/DocsLayout';
import GuestGuard from 'components/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);
// Home
const Home = Loadable(lazy(() => import('./pages/dashboard/HomePage')));
const Page1 = Loadable(lazy(() => import('./pages/dashboard/Page1')));
// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const Profile = Loadable(lazy(() => import('./pages/authentication/Profile')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));

// UserAccount Page
const UserAccount = Loadable(lazy(() => import('./pages/userAccount/UserAccountPage')));
const UserAccountDetail = Loadable(lazy(() => import('./pages/userAccount/UserAccountDetailPage')));

// Buses Page
const Buses = Loadable(lazy(() => import('./pages/dashboard/BusesPage')));
// MaintenanceService Page
const MaintenanceService = Loadable(lazy(() => import('./pages/dashboard/MaintenanceServicePage')));
const MaintenanceServiceCreateEdit = Loadable(lazy(() => import('./pages/dashboard/MaintenanceServiceCreateEditPage')));
// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      }
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'userAccounts',
        element: <UserAccount />
      },
      {
        path: 'userAccounts/detail/:internalId',
        element: <UserAccountDetail />
      },
      {
        path: 'userAccounts/edit/:internalId',
        element: <UserAccountDetail />
      },
      {
        path: 'buses',
        element: <Buses />
      },
      {
        path: 'maintenanceService',
        element: <MaintenanceService />
      },
      {
        path: 'maintenanceService/create',
        element: <MaintenanceServiceCreateEdit />
      },
      {
        path: 'maintenanceService/edit/:internalId',
        element: <MaintenanceServiceCreateEdit />
      }
    ]
  },
  {
    path: 'pages',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'page1',
        element: <Page1 />
      },
      {
        path: 'page2',
        element: <Page1 />
      },
      {
        path: 'page3',
        element: <Page1 />
      }
    ]
  },
  {
    path: 'docs',
    element: <DocsLayout />,
    children: [
      {
        path: 'docs',
        element: (
          <Navigate
            to="/docs/overview/welcome"
            replace
          />
        )
      },
      {
        path: '*',
        element: <Docs />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
