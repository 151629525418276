import ChartSquareBarIcon from 'icons/ChartSquareBar';
import ArchiveIcon from 'icons/Archive';
import UserIcon from 'icons/User';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';

interface ISectionItem {
  title: string;
  path: string;
  icon?: JSX.Element;
  children?: ISectionItem[];
}

export const NameMap: { [key: string]: string } = {
  '/home': 'Visión General',
  '/pages': 'Paginas',
  '/pages/page1': 'Pagina 1',
  '/pages/page2': 'Pagina 2',
  '/pages/page3': 'Pagina 3',
  '/buses': 'Buses',
  '/maintenanceService': 'Servicio Mantención',
  '/maintenanceService/create': 'Crear',
  'maintenanceService/edit/:internalId': 'Editar',
  '/userAccounts': 'Usuarios',
  '/userAccounts/detail': 'Detalle',
  '/userAccounts/edit': 'Editar',
};

const sectionsNames: ISectionItem[] = [
  {
    title: NameMap['/home'],
    path: '/',
    icon: <ChartSquareBarIcon fontSize="small" />
  },
  {
    title: NameMap['/userAccounts'],
    path: '/userAccounts',
    icon: <UserIcon fontSize="small" />
  },
  {
    title: 'Paginas',
    path: '/pages',
    icon: <ArchiveIcon fontSize="small" />,
    children: [
      {
        title: NameMap['/pages/page1'],
        path: '/pages/page1',
      },
      {
        title: NameMap['/pages/page2'],
        path: '/pages/page2',
      },
      {
        title: NameMap['/pages/page3'],
        path: '/pages/page3',
      }
    ]
  },
  {
    title: NameMap['/buses'],
    path: '/buses',
    icon: <DirectionsBusIcon fontSize="small" />
  },
  {
    title: NameMap['/maintenanceService'],
    path: '/maintenanceService',
    icon: <DepartureBoardIcon fontSize="small" />
  },
];

const sections = [
  {
    title: 'General',
    items: sectionsNames
  }
];

export default sections;
