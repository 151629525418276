import client from 'api/client';
import { ApiEmployee, IEmployee } from 'types/employee';
import { capitalizeString } from 'utils/stringHelper';

export async function getEmployees() : Promise<IEmployee[] | null> {
  try {
    const employees = await client.get<ApiEmployee[]>('/Drivers/getalldrivers/false');
    if (employees && employees.data) {
      const result = employees.data.map<IEmployee>((e) => ({
        id: e.id,
        rol: e.rol,
        rut: `${e.rut}-${e.dv}`,
        costCentre: e.costCentreId,
        fullname: capitalizeString(`${e.firstName} ${e.secondName} ${e.lastName} ${e.secondLastName}`),
        roleName: 'Conductor'
      }));
      return result;
    }
    return undefined;
  } catch {
    return undefined;
  }
}
