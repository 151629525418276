import { FC, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, Hidden,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import Content from './SidebarContent';

interface DefaultSidebarProps {
  onClose: () => void;
  open: boolean;
  onMobile: boolean;
}

const DefaultSidebar: FC<DefaultSidebarProps> = (props) => {
  const { onClose, open, onMobile } = props;
  const location = useLocation();
  useEffect(() => {
    if (open && onClose && onMobile) {
      onClose();
    }
  }, [location.pathname]);
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onClose}
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="temporary"
        >
          <Content />
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          onClose={onClose}
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          <Content />
        </Drawer>
      </Hidden>
    </>
  );
};

DefaultSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onMobile: PropTypes.bool
};

export default DefaultSidebar;
